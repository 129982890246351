<template>
    <div>
        <post
            v-if="postViews.includes(item.view)"
            :data="item"
            :key="index"
            :on-like="onLike"
            :on-delete="onDelete"
        />
        <notification
            v-else-if="notificationViews.includes(item.view)"
            :data="item"
            :key="index"
        />
        <material
            v-else
            :date="item.created_at"
            :key="index"
        >123
        </material>
    </div>
</template>

<script>
    export default {
        name: 'WallItem',
        components: {
            Post : () => import('./Post/Post'),
            Material : () => import('./Post/Material'),
            Notification : () => import('./Post/Notification')
        },
        props: {
            index: {
                type: Number,
                required: true
            },
            item: {
                type: Object,
                required: true
            },
            onLike: {
                type: Function,
                required: false,
                default: null
            },
            onDelete: {
                type: Function,
                required: false,
                default: null
            }
        },
        data() {
            return {
                notificationViews: ['user', 'law_break', 'incentive'],
                postViews: ['post', 'advert']
            }
        }
    }
</script>

<style scoped>

</style>
